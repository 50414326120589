<template>
	<div>
		<h4 class="h3_title" style="text-align: center">{{ symbol }}</h4>
	
		<div style="margin: 30px auto 50px auto;width: 150px">
			<van-image :src="require('@/assets/images/deposit_icon.png')" width="150px" />
		</div>

		<van-cell-group inset>
			<van-cell :title="methodSelect" :label="label" @click="showPicker = true" center clickable>
				<template #right-icon>
					<van-icon name="exchange" size="26"/>
				</template>
			</van-cell>
		</van-cell-group>

		<van-popup v-model="showPicker" round position="bottom">
			<van-picker
				title="入金方式"
				show-toolbar
				:columns="methods"
				@cancel="showPicker = false"
				@confirm="onConfirm"
			/>
		</van-popup>
	</div>
</template>

<script>
	export default {
		name: 'deposit_method',
		data() {
			return {
				formItem: {
					token: "",
					currency_id: "",
					symbol: "",
					merchant_id: ""
				},

				symbol: "",

				showPicker: false,
				label: '入金方式',
				methodSelect: '请选择入金方式',
				methods: [],
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')

				return false
			} 

			var deposit_info = window.sessionStorage.getItem('deposit_info')

			if (!deposit_info) {
				this.$router.replace('/deposit/index?token=' + this.$route.query.token)

				return false
			}

			deposit_info = JSON.parse(deposit_info)

			if (deposit_info['currency_id'] == undefined || !deposit_info['currency_id']) {
				this.$router.replace('/deposit/index?token=' + this.$route.query.token)

				return false
			}
			
			this.formItem.token = this.$route.query.token
			this.formItem.currency_id = deposit_info['currency_id']

			this.init()
		},
		methods: {
			init() {
				this.$axios({
					method: 'post',
					url: 'cash_deposit/methods', 
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.symbol = res.data.symbol
						this.formItem.symbol = res.data.symbol
						this.methods = res.data.methods

						this.showPicker = true
					}
				})
			},
			onConfirm(value) {
				this.formItem.merchant_id = value.value
				this.methodSelect = value.text

				this.showPicker = false

				window.sessionStorage.setItem('deposit_info', JSON.stringify(this.formItem))

				if (value.maintenance == 1) {
					this.$router.push('/deposit/maintenance?token=' + this.formItem.token)
				} else {
					if (value.currency_type == 1) {
						this.$router.push('/deposit/fm?token=' + this.formItem.token)
					} else {
						this.$router.push('/deposit/dc?token=' + this.formItem.token)
					}
				}
			}
		},
	}
</script>
